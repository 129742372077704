import React, { useEffect, useState } from "react";
import AprableekLogo from "../../assets/Images/aqrableek-logo-white.jpg";
import PP66title from "../../assets/Images/PP66.png";
import Avtar from "../../assets/Images/user.png";
import { MdPushPin, MdMessage, MdVerified } from "react-icons/md";
import { IoMdShare } from "react-icons/io";
import { HiEye } from "react-icons/hi";
import { TiArrowUpOutline } from "react-icons/ti";
import {
  getDatabase,
  onValue,
  orderByChild,
  query,
  ref,
  startAt,
} from "firebase/database";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatTimeDifference } from "../../helper/formateTiming";
import { formatPeriods } from "../../helper/filterTweetUtils";
import LoadingSkeleton from "../../components/loaadingSkeleton/loadingSkeleton";
import { getTodayDate } from "../../helper/uploadData";

const database = getDatabase();
const timestamp720HoursAgo = getTodayDate(720);

const Home = () => {
  const [bestPosts, setBestPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const calculateScore = (post) => {
    const likeListLength = Array.isArray(post.likeList)
      ? post.likeList.length
      : 0;
    const commentCount = post.commentCount || 0;
    const views = Array.isArray(post.viewsList) ? post.viewsList.length : 0;
    return likeListLength * 100 + commentCount * 10 + views;
  };

  const getBestPosts = (posts) => {
    // Filter posts that have necessary attributes
    const filteredPosts = posts.filter(
      (item) =>
        item.createdAt &&
        item.likeList &&
        item.commentCount !== undefined &&
        item.viewsList
    );

    let bestPostOfDay = null;
    let bestPostOfWeek = null;
    let bestPostOfMonth = null;
    let highestDayScore = 0;
    let highestWeekScore = 0;
    let highestMonthScore = 0;

    const now = moment();

    // Loop through filtered posts to assign the best post for day, week, or month
    filteredPosts.forEach((post) => {
      const createdAt = moment(post.createdAt);
      const score = calculateScore(post);

      // Calculate the difference in hours between the current time and post creation time
      const hoursDifference = now.diff(createdAt, "hours");

      // Check if the post is from today (less than 24 hours ago)
      if (hoursDifference < 24 && score > highestDayScore) {
        highestDayScore = score;
        bestPostOfDay = post;
      }

      // Check if the post is from this week (less than 7 days ago)
      if (hoursDifference < 168 && score > highestWeekScore) {
        highestWeekScore = score;
        bestPostOfWeek = post;
      }

      // Check if the post is from this month (less than 30 days ago)
      if (hoursDifference < 720 && score > highestMonthScore) {
        // 30 days = 720 hours
        highestMonthScore = score;
        bestPostOfMonth = post;
      }
    });

    // Store the best posts for each time period
    const bestPostsMap = new Map();

    // Helper function to add posts to the map
    const addPost = (post, period) => {
      const existing = bestPostsMap.get(post);
      if (!existing) {
        bestPostsMap.set(post, { ...post, periods: [period] });
      }
    };

    if (bestPostOfDay) addPost(bestPostOfDay, "day");
    if (bestPostOfWeek) addPost(bestPostOfWeek, "week");
    if (bestPostOfMonth) addPost(bestPostOfMonth, "month");

    // Create an array of best posts
    const bestPosts = Array.from(bestPostsMap.values());
    const createUniqueKey = (post) => `${post.userId}-${post.createdAt}`;
    const bestPostIds = new Set(bestPosts.map(createUniqueKey));

    // Filter out posts that are already included in the bestPosts
    const additionalPosts = filteredPosts
      .filter((post) => !bestPostIds.has(createUniqueKey(post)))
      .map((post) => post);

    // Limit additional posts to 10
    const limitedAdditionalPosts = additionalPosts.slice(
      0,
      Math.min(10, additionalPosts.length)
    );

    // Return the combined list of best posts and additional posts
    setIsLoading(false);
    return [...bestPosts, ...limitedAdditionalPosts];
  };

  useEffect(() => {
    const tweetVoiceRef = ref(database, `tweetVoice/Arabic worlds`);
    const recentTweetVoiceQuery = query(
      tweetVoiceRef,
      orderByChild("createdAt"),
      startAt(timestamp720HoursAgo)
    );

    onValue(recentTweetVoiceQuery, (snapshot) => {
      if (snapshot.exists()) {
        const tweetVoiceData = snapshot.val();
        const posts = Object.values(tweetVoiceData);
        const bestPosts = getBestPosts(posts);
        setBestPosts(bestPosts);
      }
    });
  }, []);
  return (
    <>
      <div>
        <div className="bg-[#f66666] pt-[30px] pb-[15px] mb-[36px]">
          <div className="flex justify-center ">
            <button
              onClick={() => navigate("/login")}
              className={
                "text-[#fff] font-semibold p-[6px_16px] rounded-[30px_0_0_30px] border-[1px] border-[#fff]"
              }
            >
              Log In
            </button>
            <button
              onClick={() => navigate("/signup")}
              className={
                "bg-[#fff] text-[#f66666] font-semibold p-[6px_16px] rounded-[0_30px_30px_0] border-[1px] border-[#fff]"
              }
            >
              Sign Up
            </button>
          </div>
          <div className="flex justify-center pt-[30px]">
            <img
              src={AprableekLogo}
              alt=""
              className="w-[64px] h-[64px] rounded-lg"
            />
          </div>
          <div className="flex justify-center pt-[15px]">
            <img src={PP66title} alt="" className="w-[250px]" />
          </div>
          <div className="text-slate-100 mt-2 text-md text-center sm:text-[16px] text-[14px]">
            Political and philosophical quotes platform, a website <br />
            and social networking app, for life and present.
          </div>
        </div>
        {isLoading ? (
          <>
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </>
        ) : (
          bestPosts &&
          bestPosts.length > 0 && (
            <div className="shadow-[rgba(100,100,111,0.2)_0px_7px_29px_0px] bg-[#fff] rounded-[10px] max-w-[950px] w-full mx-auto p-[8px] sm:p-[12px_20px]">
              {bestPosts.map((data, ind) => (
                <div
                  key={ind}
                  onClick={() => navigate("/signup")}
                  className="border-b-[#c0bbbb] border-b-[1px] py-3 last:border-none cursor-pointer"
                >
                  {data.periods && (
                    <div className="text-gray-500 flex items-center gap-1 ml-[14px] mb-2">
                      <MdPushPin />
                      <p className="text-[12px]">
                        Best post of the {formatPeriods(data.periods)}
                      </p>
                    </div>
                  )}
                  <div className="flex sm:flex-nowrap flex-wrap items-start">
                    <div className="flex items-start sm:gap-[20px] gap-[12px] w-full">
                      <img
                        src={data?.user?.profilePic || Avtar}
                        alt="user"
                        className="sm:w-[50px] sm:min-w-[50px] w-[30px] min-w-[30px] sm:h-[50px] h-[30px] rounded-full object-cover"
                      />

                      <div className="w-full">
                        <div className="flex justify-between">
                          <div className="flex items-center gap-2">
                            <div className="flex items-center gap-1">
                              <h2 className="sm:text-[18px] text-[16px] font-semibold">
                                {data?.user?.displayName}
                              </h2>
                              {data?.user?.isVerified && (
                                <MdVerified className="text-[#ff6d51] text-[14px]" />
                              )}
                            </div>
                            <p className="text-[#858585] font-medium text-[12px]">
                              {data?.user?.userName}
                            </p>
                          </div>
                        </div>
                        <p className="text-black sm:text-[16px] text-[14px] mt-[4px] break-all">
                          {data?.description}
                        </p>
                        <div className="flex items-center gap-[24px] mt-[14px] flex-wrap">
                          <button
                            className={`flex sm:gap-[16px] gap-[6px] sm:text-[16px] text-[14px] items-center `}
                          >
                            <TiArrowUpOutline className="sm:text-[24px] text-[20px] text-[#5c5c5c]" />
                            {data?.likeList?.length || 0}
                          </button>
                          <button className="flex sm:gap-[16px] gap-[6px] sm:text-[16px] text-[14px] items-center">
                            <MdMessage className="sm:text-[24px] text-[20px] text-[#5c5c5c]" />
                            {data?.replyTweetKeyList?.length || 0}
                          </button>

                          <button className="flex sm:gap-[16px] gap-[6px] sm:text-[16px] text-[14px] items-center">
                            <HiEye className="sm:text-[24px] text-[20px] text-[#5c5c5c]" />
                            {data?.viewsList && data?.viewsList?.length * 3}
                          </button>
                          <button className="flex sm:gap-[16px] gap-[6px] sm:text-[16px] text-[14px] items-center">
                            <IoMdShare className="sm:text-[24px] text-[20px] text-[#5c5c5c]" />
                            {data?.viewsList && data?.viewsList?.length * 3}
                          </button>
                        </div>
                      </div>
                    </div>
                    <p className="text-[12px] text-gray-500 whitespace-nowrap sm:mt-0 mt-2 sm:block hidden">
                      {formatTimeDifference(data?.createdAt)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default Home;
